import * as React from 'react';

import KiteIcon from '../icons/KiteIcon/KiteIcon';

import './KiteLink.scss';

export interface ILinkProps {
  /** URL target of the link */
  href: string;
  /** Children */
  children: React.ReactNode | string;
  /** Sets the appearance of the link. Leave absent or blank to not add any supplemental styling. */
  type?: 'quick' | 'control' | 'standalone' | 'list' | 'inline' | '';
  /** Set `target="_blank"`, and open the target URL in a new tab */
  newTab?: boolean;
  /** A single string of class names to be added to the outer element of the component. If adding multiple classes, just? put them in a single, space-seperated string. */
  className: string;
}

/**
 *  [Link design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/links)
 */
const KiteLink = ({
  href,
  type,
  newTab,
  children,
  className,
  ...other
}: ILinkProps) => {
  return (
    <a
      href={href}
      className="kite-link kite-typography"
      target={newTab ? '_blank' : ''}
      {...other}
    >
      {children}
      {newTab && <span className="kite-sr-only">will open in new tab</span>}
      {(type === 'quick' || type === 'control') && (
        <KiteIcon
          name="chevron-right"
          className="kite-link__icon"
          size="16px"
        />
      )}
    </a>
  );
};

KiteLink.defaultProps = {
  type: 'quick',
  newTab: false,
  className: '',
};

export default KiteLink;
