import * as React from 'react';
import { getTypeClass, getSizeClass } from './utils/button-utils';

export interface ILinkButtonProps {
  /** URL target of the link */
  href: string;
  /** Children (Sets content of the button) */
  children: React.ReactNode;
  /** Sets the appearance of the button. */
  type?: 'primary' | 'secondary' | 'outline' | 'quick-link' | 'standalone-link';
  /** Sets the min-width of the button. */
  size?: 'full' | 'xl' | 'large' | 'medium' | 'small';
  /** Toggle the appearance and ability to interact. Also sets `aria-hidden="true"` */
  disabled?: boolean;
  /** Set `target="_blank"`, and open the target URL in a new tab */
  newTab?: boolean;
  /** A single string of class names to be added to the outer element of the component. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
}

/**
 * An HTML `<a>` element styled as a kite button. The props for `size` and `type` are repeated from the kite-button. View the design specifications for the [buttons](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/buttons) and [links](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/links)
 */
const KiteLinkButton = ({
  href,
  type,
  size,
  disabled,
  newTab,
  children,
  className,
  ...other
}: ILinkButtonProps) => {
  const typeClass = getTypeClass(type);
  const sizeClass = getSizeClass(size);
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <a
      href={href}
      className={`kite-btn ${typeClass} ${sizeClass} ${disabledClass} ${className}`}
      role="button"
      aria-hidden={disabled}
      target={newTab ? '_blank' : ''}
      {...other}
    >
      {children}
    </a>
  );
};

KiteLinkButton.defaultProps = {
  type: 'primary',
  size: 'large',
  disabled: false,
  newTab: false,
  className: '',
};

export default KiteLinkButton;
